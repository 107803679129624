import React from 'react';
import { instanceOf } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Cookies, withCookies, cookieKeys } from '../../common/auth/cookie_utils';
import { AUTH_ROLES } from '../../common/config';
import AreaManagement from '../area-management/area-management';
import MasterTeacherView from '../master-teacher/master-teacher';

const DashboardView = ({ cookies }) => {
  const role = cookies.get(cookieKeys.POSITION);
  const [toggleView, setToggleView] = React.useState(false);
  if (role === AUTH_ROLES.ADMIN_ROLE) {
    return (
      <>
        <Button onClick={() => setToggleView(!toggleView)}>{`Switch to ${
          toggleView ? 'Admin View' : 'Teacher View'
        }`}</Button>
        {toggleView ? <MasterTeacherView /> : <AreaManagement />}
      </>
    );
  }
  return <MasterTeacherView />;
};

DashboardView.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(DashboardView);
