const rowData = [
  {
    testNumber: 1,
    gameName: 'Alphabet Warm-Up Letters',
    skillTest: 'm, p, t, e, k, c, D, b, i, L, u, w, a, S, z',
    passingScore: '13/15',
    numQuestions: 15,
  },
  {
    testNumber: 2,
    gameName: 'Alphabet Warm-Up Sounds',
    skillTest: '/m/, /d/, /p/, /f/, /w/, /l/, /h/',
    passingScore: '6/7',
    numQuestions: 7,
  },
  {
    testNumber: 3,
    gameName: 'Short Vowel Kick',
    skillTest: 'pat, hit, bet, hut, hop, nak, rit, lep, tum, rof',
    passingScore: '8/10',
    numQuestions: 10,
  },
  {
    testNumber: 4,
    gameName: 'Slight Word Drop Kick',
    skillTest:
      'is, of, two, are, the, you, does, give, said, some, want, were, their, where, would',
    passingScore: '0/15',
    numQuestions: 15,
  },
  {
    testNumber: 5,
    gameName: 'Consonant Digraph Corner Kick',
    skillTest: 'shoe, church, photo, song, what',
    passingScore: '5/5',
    numQuestions: 5,
  },
  {
    testNumber: 6,
    gameName: 'Long & Short Vowel Pass',
    skillTest: 'No Assessment',
    passingScore: 'n/a',
    numQuestions: 0,
  },
  {
    testNumber: 7,
    gameName: 'Consonant Blend Bicycle Kick',
    skillTest: 'flag, skip, truck, snap, glad, spin, club',
    passingScore: '5/7',
    numQuestions: 7,
  },
  {
    testNumber: 8,
    gameName: 'Consonant Blend & Digraph Dribble',
    skillTest: 'No Assessment',
    passingScore: 'n/a',
    numQuestions: 0,
  },
  {
    testNumber: 9,
    gameName: 'Long Vowel Throw In',
    skillTest: 'street, pain, beat, foe, clay, boat',
    passingScore: '4/6',
    numQuestions: 6,
  },
  {
    testNumber: 10,
    gameName: '"C" and "G" Slide Tackle',
    skillTest: 'city, cat, go, gem, cep, cote, gak, gip',
    passingScore: '4/8',
    numQuestions: 8,
  },
  {
    testNumber: 11,
    gameName: 'Affix Header',
    skillTest: 'slipped, mailed, wanted, thinking, coats, slows',
    passingScore: '4/6',
    numQuestions: 6,
  },
  {
    testNumber: 12,
    gameName: 'Diphthong Diving Save',
    skillTest: 'cloud, plow, soil, grew, blue, haul, paw, look, cook',
    passingScore: '5/9',
    numQuestions: 9,
  },
  {
    testNumber: 13,
    gameName: 'R-controlled Penalty Kick',
    skillTest: 'cork, fir, car, term, fur',
    passingScore: '4/5',
    numQuestions: 5,
  },
];

export default rowData;
