import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './settings.module.css';
import AccountInformationView from './accountInformationView';

const TeacherView = ({ userInfo }) => {
  return (
    <div id={styles['mt-settings']}>
      <AccountInformationView userInfo={userInfo} />
    </div>
  );
};

TeacherView.defaultProps = {
  userInfo: {
    fullName: '',
    email: '',
    phoneNumber: '',
    status: '',
  },
};

TeacherView.propTypes = {
  userInfo: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default TeacherView;
