import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { IoCloseCircleOutline } from 'react-icons/io5';
import styles from './AssessmentScoreCard.module.css';

const AssessmentRow = ({
  formName,
  fieldIndex,
  editState,
  note,
  gameName,
  playerScore: initialPlayScore,
  skillTest,
  passingScore,
  numQuestions,
  update,
}) => {
  const { register, watch } = useFormContext();
  const playerScore = watch(`${formName}.${fieldIndex}.playerScore`);
  const offSet = numQuestions - initialPlayScore;
  const [chipToggle, setChipToggle] = React.useState(() => {
    if (skillTest === 'No Assessment') return {};
    return skillTest?.split(',').reduce((acc, word, i) => {
      if (offSet < numQuestions && offSet > i) {
        acc[`${gameName}: ${word}`] = true;
      } else {
        acc[`${gameName}: ${word}`] = false;
      }
      return acc;
    }, {});
  });

  register(`${formName}.${fieldIndex}.playerScore`);

  const handleChipClick = (e, word) => {
    e.preventDefault();

    const chipState = !chipToggle[word];
    let newScore = 0;

    if (!playerScore) {
      newScore = chipState ? numQuestions - 1 : playerScore + 1;
    } else {
      newScore = chipState ? playerScore - 1 : playerScore + 1;
    }

    setChipToggle({ ...chipToggle, [word]: chipState });
    update(`${formName}.${fieldIndex}.playerScore`, newScore);
  };
  const notesInput = () => {
    if (editState === 'editing') {
      return (
        <textarea
          type="text"
          maxLength="255"
          placeholder="Input Notes Here"
          className={styles['row-note-input']}
          {...register(`${formName}.${fieldIndex}.note`)}
        />
      );
    }
    if (note === '') {
      return <>Input Notes Here</>;
    }
    return note;
  };

  const scoreInput = () => {
    if (numQuestions === 0) return '0';
    if (editState === 'editing') return `${playerScore}/${numQuestions}`;

    return `${playerScore || 0}/${numQuestions}`;
  };

  return (
    <tr className={styles['assessment-row']}>
      <td className={styles['game-name']}>{gameName}</td>
      <td className={styles['skill-test']}>
        {skillTest?.split(',')?.map(word => {
          return editState === 'editing' && word.trim() !== 'No Assessment' ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <span
              className={
                chipToggle[`${gameName}: ${word}`]
                  ? `${styles['basic-chip']} ${styles['chip-disabled']}`
                  : styles['basic-chip']
              }
              key={word}
              onClick={e => handleChipClick(e, `${gameName}: ${word}`)}
              role="button"
              tabIndex="0"
            >
              {word?.trim()}
              <i className={`${styles['close-chip-icon']}`}>
                <IoCloseCircleOutline />
              </i>
            </span>
          ) : (
            word
          );
        })}
      </td>
      <td className={`${styles['row-notes']} ${note?.length === 0 ? styles['empty-notes'] : ''}`}>
        {notesInput()}
      </td>
      <td className={styles['passing-score']}>{numQuestions !== 0 ? passingScore : 'N/A'}</td>
      <td className={styles['player-score']}>{scoreInput()}</td>
    </tr>
  );
};

AssessmentRow.defaultProps = {
  playerScore: 0,
  note: '',
};

AssessmentRow.propTypes = {
  formName: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  editState: PropTypes.string.isRequired,
  playerScore: PropTypes.number,
  note: PropTypes.string,
  gameName: PropTypes.string.isRequired,
  skillTest: PropTypes.string.isRequired,
  passingScore: PropTypes.string.isRequired,
  numQuestions: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
};

export default AssessmentRow;
