import React from 'react';
import AreaView from '../../components/AreaView/AreaView';
// import './area-details.modules.css';

const AreaDetails = () => {
  return (
    <div>
      <AreaView />
    </div>
  );
};

export default AreaDetails;
